import LazyLoad from "vanilla-lazyload";
require("./rwd-checker");
require("./form-animation");

$(function() {
  const lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // aby użyć do elementów dodajemy klasę .lazy
    // zamiast src w img podajemy data-src
    // zamiast background-image podajemy data-bg
  });

  require("./cookie-bar");
  require("./animations");
  require("./counter-animation");
  require("./dropdown-menu");
  require("./mobile-menu");
  require("./sticky-header");
  require("./pop-up");
  require("./simple-lightbox");

  $(".gallery, .lightbox").each(function() {
    $(this)
      .find("a")
      .simpleLightbox();
  });
  
  if ($(".simplelightbox").length > 0) {
    $(".simplelightbox").simpleLightbox();
  }

  $('.top__slider, .product__images').slick({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrows: false,
    autoplay: true,
    fade: true,
    cssEase: 'linear'
  });


  $('.onDownload').on('click', function() {
    handleDownload($(this).data('pid'));


    function handleDownload(pid) {
      $.request('Products::onUpdateDownloaded', {
          data: {
              pid: pid,
          },
          success: function(data) {
            console.log(data);
          }, 
          error: function(data) {
    
          }
      });
    };
  });

  const searchInput = document.querySelector('.search__input');

  searchInput.addEventListener('keyup', function(event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
        const searchQuery = searchInput.value.trim();
        if (searchQuery) {
            const searchUrl = `/search?keywords=${encodeURIComponent(searchQuery)}`;
            window.location.href = searchUrl;
        }
    }
  });


    var removeAlert = function() {
        $('.alert-container').css('opacity', 0).css('top', '-100px');

        setTimeout(() => {
            $('.alert-container').remove();
        }, 500);
    }

    if($('.alert-container')) {

      setTimeout(() => {
        $(document).on('click scroll', function(){
          removeAlert();
        })
      }, 3000);
      
      setTimeout(() => {
        removeAlert();
      }, 5000);
    }

    $('.account__toggle-tab').on('click',function(e){
      e.preventDefault();
      $('.account__tab, .account__toggle-tab').toggleClass('active');
    })
});